import axios from "axios";
import { sendUserInfo } from "./interactWithFlutter";
import { setLoadingState } from "./Loader";

// Axios 인스턴스 생성
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// 요청 인터셉터 설정
apiClient.interceptors.request.use(
  (config) => {
    // 세션 스토리지에 로딩 상태 설정
    setLoadingState(true);

    // 기본 헤더에 토큰 설정
    const token = localStorage.getItem("accessToken");
    const i18nextLng = localStorage.getItem("i18nextLng");
    const language = localStorage.getItem("language");

    config.headers["Authorization"] = `Bearer ${token}`;
    if (config.headers["Authorization"] === "Bearer null") {
      setLoadingState(false); // 요청 실패 시 로딩 상태 제거
      throw Error("토큰이 존재하지 않습니다.");
    }

    // 언어 설정
    let acceptLanguage;
    if (i18nextLng) {
      acceptLanguage = i18nextLng;
    }
    if (language) {
      acceptLanguage = language;
    }
    if (acceptLanguage) {
      config.headers["Accept-Language"] = acceptLanguage;
    }

    return config;
  },
  (error) => {
    setLoadingState(false); // 요청 실패 시 로딩 상태 제거
    return Promise.reject(error);
  }
);

// 응답 인터셉터 설정
apiClient.interceptors.response.use(
  (response) => {
    // 응답이 성공적인 경우 로딩 상태 제거
    setLoadingState(false);
    return response;
  },
  (error) => {
    // 응답 실패 시 로딩 상태 제거
    setLoadingState(false);

    if (error.response && error.response.status === 401) {
      // 401 에러 처리
      localStorage.removeItem("accessToken");
      sendUserInfo("signout");
    }
    return Promise.reject(error);
  }
);

export default apiClient;
