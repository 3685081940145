import React from "react";
import styled from "styled-components";

const NormalLoader = () => {
  return (
    <NormalLoaderContainer>
      <Dots>
        <Dot />
        <Dot />
        <Dot />
      </Dots>
    </NormalLoaderContainer>
  );
};

const NormalLoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const Dots = styled.div`
  display: flex;
  gap: 15px;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  animation: bounce 1.2s infinite ease-in-out;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
  }
`;

export default NormalLoader;
