import React, { useEffect, Suspense, lazy, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import GlobalStyle from "./components/GlobalStyle";
import { Toaster } from "react-hot-toast";
import { UserProvider, useUserContext } from "./contexts/UserContext";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import "./utils/setupTimeAgo";
import ContactForm from "./pages/ContactForm";
import useDynamicFontSize from "./hooks/useDynamicFontSize";
import styled from "styled-components";
import "./App.css";
import {
  init,
  Identify,
  setUserId,
  logEvent,
  track,
  add,
} from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import MaintenanceModal from "./components/common/MaintenanceModal";
import Report from "./pages/Report";
import { useTranslation } from "react-i18next";
import LoadingRefresh from "./components/Etc/LoadingRefresh";
import ErrorBoundary from "./components/Etc/ErrorBoundary";
import { ThemeProvider } from "styled-components";
import { useDarkMode } from "./contexts/DarkModeContext";
import { lightTheme, darkTheme } from "./theme";
import useGlobalAutoScroll from "./hooks/useGlobalAutoScroll";
import { ToastProvider } from "./hooks/ToastContext";
import { clearCache } from "./utils/interactWithFlutter";
import Loader from "./utils/Loader";
import NormalLoader from "./utils/normalLoader";

const Record = lazy(() => import("./pages/Record"));
const Welcome = lazy(() => import("./pages/Welcome"));
const Poop = lazy(() => import("./pages/Poop"));
const RecordDetail = lazy(() => import("./pages/RecordDetail"));
const UserInputPhase = lazy(() => import("./pages/UserInputPhase"));
const PoopAnalysis = lazy(() => import("./pages/PoopAnalysis"));
const Mypage = lazy(() => import("./pages/Mypage"));
const Withdrawal = lazy(() => import("./pages/Withdrawal"));
const Login = lazy(() => import("./pages/Login"));
const Design = lazy(() => import("./pages/Design"));
const Food = lazy(() => import("./pages/Food"));
const Inquiry = lazy(() => import("./pages/Inquiry"));
const Statistics = lazy(() => import("./pages/Statistics"));
const Saju = lazy(() => import("./pages/Saju"));
const SajuDetail = lazy(() => import("./pages/SajuDetail"));
const Setting = lazy(() => import("./pages/Setting"));
const Notice = lazy(() => import("./pages/Notice"));
const UserProfile = lazy(() => import("./pages/UserProfile"));
const Event = lazy(() => import("./pages/Event"));
const EventDetail = lazy(() => import("./pages/EventDetail"));
// const Event1Ticket = lazy(() => import("./data/events/event1Ticket"));
const TermsOfUse = lazy(() => import("./pages/TermsOfUse"));
const BabyDiary = lazy(() => import("./pages/BabyDiary"));
const EventWinners = lazy(() => import("./pages/EventWinners"));
const App = () => {
  const { isDarkMode } = useDarkMode();
  useGlobalAutoScroll();
  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <ErrorBoundary>
        <Loader />
        <UserProvider>
          <Router>
            <ToastProvider>
              <AppContainer>
                <AppContent />
              </AppContainer>
            </ToastProvider>
          </Router>
        </UserProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const ContentContainer = styled.div`
  max-width: 40rem;
  width: 100%;
  background-color: white;
  margin: 0 auto;
  position: relative;
`;

const AppContent = () => {
  const { state, checkProfile, dispatch } = useUserContext();
  const { profile, babies } = state;
  const location = useLocation();
  const [initialPath, setInitialPath] = useState("/");
  const [isChecking, setIsChecking] = useState(true);
  const [isOneStore, setIsOneStore] = useState(false);
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    const handleStorageChange = (event) => {
      if (event.key === "language" || event.key === "language_changed") {
        const newLanguage = localStorage.getItem("language");
        if (newLanguage) {
          i18n.changeLanguage(newLanguage);
        }
      }
      if (event.key === "appVersion") {
        setIsOneStore(false);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // Check if the browser is Chrome
    const isChromeBrowser =
      /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

    const appVersion = localStorage.getItem("appVersion");
    if (!appVersion && isChromeBrowser) {
      setIsOneStore(true);
    }

    // Amplitude 초기화
    const apiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;

    const sessionReplayTracking = sessionReplayPlugin();
    add(sessionReplayTracking);

    init(apiKey, {
      defaultTracking: {
        pageViews: false,
        sessions: {
          toBeEnabled: true,
          sessionEvent: "Start Session",
        },
        formInteractions: true,
        fileDownloads: true,
      },
      flushIntervalMillis: 10000, // 10초마다 전송
    });

    if (profile) {
      // 사용자 식별
      const userId = `${profile.email}-${profile.provider}`;
      setUserId(userId);

      // 사용자 속성 설정
      const identify = new Identify();
      identify.set("email", profile.email);
      identify.set("provider", profile.provider);
      logEvent("Identify", identify.properties);
    }

    if (!isAlwaysAccessiblePath) {
      const fetchData = async () => {
        await checkProfile();
        setIsChecking(false);
      };
      fetchData();
    } else {
      setIsChecking(false);
    }

    // 현재 경로를 저장합니다.
    if (!localStorage.getItem("accessToken")) {
      setInitialPath(location.pathname);
    }

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    // 'cacheCleared' 키를 사용하여 로컬 스토리지에서 값을 검사
    const isCacheCleared = localStorage.getItem("cacheCleared");

    if (!isCacheCleared) {
      clearCache(); // 캐시를 클리어하는 함수 실행
      localStorage.setItem("cacheCleared", "true"); // 캐시 클리어 플래그를 설정
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = async (event) => {
      if (!isAlwaysAccessiblePath && localStorage.getItem("accessToken")) {
        const currentProfile = JSON.parse(localStorage.getItem("profile"));
        const currentBabies = JSON.parse(localStorage.getItem("babies"));
        const currentSelectedBaby = JSON.parse(
          localStorage.getItem("selectedBaby")
        );

        if (event.key === "profile") {
          const newProfile = JSON.parse(event.newValue);
          // 현재 프로필과 새 프로필이 다를 때만 checkProfile 호출
          if (currentProfile?.email !== newProfile?.email) {
            await checkProfile();
          }
        }

        if (event.key === "babies") {
          const newBabies = JSON.parse(event.newValue);
          // 현재 아기 목록과 새 아기 목록이 다를 때만 상태 업데이트
          if (JSON.stringify(currentBabies) !== JSON.stringify(newBabies)) {
            dispatch({ type: "SET_BABIES", payload: newBabies });
          }
        }

        if (event.key === "selectedBaby") {
          const newSelectedBaby = JSON.parse(event.newValue);
          // 현재 선택된 아기와 새 선택된 아기가 다를 때만 상태 업데이트
          if (
            JSON.stringify(currentSelectedBaby) !==
            JSON.stringify(newSelectedBaby)
          ) {
            dispatch({ type: "SELECT_BABY", payload: newSelectedBaby });
          }
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const alwaysAccessiblePaths = [
    "/login",
    "/login/:provider",
    "/withdrawal",
    "/privacy-policy",
    "/contact",
    "/inquiry",
    "/design",
    "/terms-of-use",
  ];
  const isAlwaysAccessiblePath = alwaysAccessiblePaths.some((path) =>
    location.pathname.startsWith(path)
  );
  useDynamicFontSize();

  if (isChecking) {
    return <NormalLoader />;
  }
  return (
    <>
      <Toaster position="bottom-center" reverseOrder={false} />
      <Suspense fallback={<NormalLoader />}>
        <ContentContainer>
          <Routes>
            <Route path="/login/:provider" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/withdrawal" element={<Withdrawal />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/inquiry" element={<Inquiry />} />
            <Route path="/design" element={<Design />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/event" element={<Event />} />
            <Route path="/event/:id" element={<EventDetail />} />
            <Route path="/event/winners" element={<EventWinners />} />
            {!profile ? (
              <>
                <Route
                  path="*"
                  element={<UserInputPhase onProfileCreated={checkProfile} />}
                />
              </>
            ) : babies.length === 0 ? (
              <>
                <Route path="*" element={<Welcome />} />
              </>
            ) : (
              <>
                <Route path="/record/detail" element={<RecordDetail />} />
                <Route path="/poop" element={<Poop />} />
                <Route path="/analysis" element={<PoopAnalysis />} />
                <Route path="/mypage" element={<Mypage />} />
                <Route path="/baby" element={<Welcome />} />
                <Route path="/food" element={<Food />} />
                <Route path="/record" element={<Record />} />
                <Route path="*" element={<Record />} />
                <Route path="/stat" element={<Statistics />} />
                <Route path="/report" element={<Report />} />
                <Route path="/saju" element={<Saju />} />
                <Route path="/saju/detail" element={<SajuDetail />} />
                <Route path="/setting" element={<Setting />} />
                <Route path="/notice" element={<Notice />} />
                <Route path="/profile" element={<UserProfile />} />

                {/* <Route path="/event/ticket" element={<Event1Ticket />} /> */}
                <Route path="/baby-diary" element={<BabyDiary />} />
              </>
            )}
          </Routes>
        </ContentContainer>
      </Suspense>
      {/* <MaintenanceModal /> */}
      <GlobalStyle />
    </>
  );
};

export default App;
