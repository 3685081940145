import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

// 커스텀 이벤트 생성
export const LOADING_EVENT = 'loadingChange';

// loading 상태 변경을 위한 헬퍼 함수
export const setLoadingState = (isLoading) => {
  if (isLoading) {
    sessionStorage.setItem('loading', 'true');
  } else {
    sessionStorage.removeItem('loading');
  }
  // 커스텀 이벤트 발생
  window.dispatchEvent(new CustomEvent(LOADING_EVENT, { detail: isLoading }));
};

const Loader = () => {
  const [isLoading, setIsLoading] = useState(
    sessionStorage.getItem("loading") === "true"
  );
  const timeoutRef = useRef(null);

  useEffect(() => {
    const handleLoadingChange = (event) => {
      setIsLoading(event.detail);
      
      if (event.detail) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
          setLoadingState(false);
        }, 10000);
      }
    };

    window.addEventListener(LOADING_EVENT, handleLoadingChange);

    return () => {
      window.removeEventListener(LOADING_EVENT, handleLoadingChange);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  if (!isLoading) return null;

  return (
    <LoaderContainer>
      <Dots>
        <Dot />
        <Dot />
        <Dot />
      </Dots>
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const Dots = styled.div`
  display: flex;
  gap: 15px;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  animation: bounce 1.2s infinite ease-in-out;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bounce {
    0%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-15px);
    }
  }
`;

export default Loader;
